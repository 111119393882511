<template>
  <div class="continue-learning__card">
    <h5 class="cc__title">
      {{ title }}
    </h5>
    <h6 class="cc__discover">
      {{ subTitle }}
    </h6>
    <ul class="cc__courselist">
      <li
        v-for="(course, index) in cInprogressCourses"
        :key="index"
        @click="onCardClick(course)"
      >
        <card :title="course.title" :imagePath="course.image_url" />
      </li>
    </ul>
    <a href="#" class="cc__viewmore" @click="onViewMore"
      >{{ $t("course_detail.view_more") }}
      <img src="@/assets/images/achievements/arrow-right.svg"
    /></a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Card from "./ContinueLearning/Card.vue";

export default {
  props: {
    courses: {
      type: Array,
      required: true
    }
  },
  components: {
    Card
  },
  computed: {
    ...mapGetters(["enrolledCourses"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    cInprogressCourses() {
      let arr = [];
      if (this.isAuthenticated) {
        arr = this.enrolledCourses.results
          .filter(e => e.metrics.progress_percentage > 0)
          .splice(-4);
        arr.forEach(r => {
          r.imgError = false;
        });
      } else {
        arr = this.courses;
      }
      return arr;
    },
    title() {
      let text;
      if (this.isAuthenticated) {
        text = this.$t("my_achievements.continue_learning.title");
      } else {
        text = this.$t("course.start_learning");
      }
      return text;
    },
    subTitle() {
      let text;
      if (this.isAuthenticated) {
        text = this.$t("my_achievements.continue_learning.sub_title");
      } else {
        text = this.$t(
          "my_achievements.continue_learning.find_recommended_courses"
        );
      }
      return text;
    }
  },
  methods: {
    onCardClick(c) {
      this.$router.push({ name: "Courses Detail", params: { id: c.id } });
    },
    onViewMore() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "my-learning" });
      } else {
        this.$router.push({ name: "Courses" });
      }
    },
    handleImgError(course) {
      course.imgError = true;
      this.getImgSrc(course);
    },
    getImgSrc(course) {
      let path = "";
      if (!course.imgError) {
        course.image_url
          ? (path = course.image_url)
          : (path = require("@/assets/images/not-found/course-img-not-found.svg"));
      } else {
        path =
          "https://files.lxp.academy.who.int/didactica/assets/images/course-card/course-illustration.svg";
      }
      return path;
    }
  }
};
</script>
<style lang="scss" scoped>
.continue-learning__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
  background: #fff;
  .cc__viewmore {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 4px 2px;
    @include label-large($brand-primary-400, 500);
  }
  .cc__title {
    color: $brand-neutral-900;
    @include subtitle-regular;
    margin: 0;
  }
  .cc__discover {
    @include label-large;
  }
  .cc__courselist {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
}
</style>
